export const PagingArrow = ({ className, ariaLabel }) => (
  <svg
    role="img"
    aria-label={ariaLabel}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="none"/>
    <path d="M13.6992 7L8.69922 12L13.6992 17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
