import ReactionLikes from './ReactionLikes';
import styles from '../funmodule.module.scss';

export const InOut = (props) => {
  const { linkUrl, image, linkTitle, title, list } = props;
  const handleLinkClick = () => {
    console.log('Link clicked');
  };
  return (
    <div className={`${styles.container} ${styles.twoColumns} ${styles.article} ${styles.inOut}`}>
        <a
          className={`${styles.imageWrapper} ${styles.imageWrapperWide}`}
          href={linkUrl}
          onClick={handleLinkClick}
        >
          <img src={image} alt={linkTitle} loading="lazy"/>
        </a>
      <div className={styles.textWrapper}>
        <div className={styles.text}>
          <h2 className={styles.title}>
            {title}
          </h2>
          {!!list.length && (
            <ul className={styles.list}>
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
        <ReactionLikes />
      </div>
    </div>
  );
};

export default InOut;
