export const PagingStar = ({ className }) => (
  <svg
    role="img"
    aria-label="Slide active"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.49841 1.39129C4.81898 -0.463764 7.48252 -0.463763 7.80309 1.39129C7.98141 2.4232 9.05149 3.04101 10.0343 2.67949C11.8011 2.02958 13.1329 4.33628 11.6867 5.54143C10.8822 6.21181 10.8822 7.44743 11.6867 8.11782C13.1329 9.32297 11.8011 11.6297 10.0343 10.9798C9.05149 10.6182 7.98141 11.236 7.80309 12.268C7.48252 14.123 4.81898 14.123 4.49841 12.268C4.32009 11.236 3.25001 10.6182 2.26719 10.9798C0.500377 11.6297 -0.831392 9.32297 0.614847 8.11782C1.41935 7.44743 1.41935 6.21181 0.614847 5.54143C-0.831392 4.33628 0.500377 2.02958 2.26719 2.67949C3.25001 3.04101 4.32009 2.4232 4.49841 1.39129Z" fill="#5246F5FF"/>
  </svg>
);
