import AdManager from '@buzzfeed/adlib/dist/module/core/base/manager';
import { sizes } from '@buzzfeed/adlib/dist/module/bindings/react';
import sizeHelper from '@buzzfeed/adlib/services/size/standalone';

export class StoryFeedManager extends AdManager {
  // FIXME? - had to give defaults and manually set config...
  constructor({ programmaticOnly = false }) {
    super(...arguments);
    this._state.adCounter = 0;
    this.programmaticOnly = programmaticOnly;
  }

  getNextAd() {
    const ad = super.getNextAd(...arguments);
    if (ad && !StoryFeedManager.isDone(ad)) {
      ad.key = `${ad.slot.adPos}-${this._state.adCounter}`;
      this._state.adCounter++;
    }
    if (this.programmaticOnly) {
      ad.slot.size = sizeHelper.exclude(
        ad.slot.size,
        sizes.FLUID,
        sizes.NATIVE
      );
    }
    return ad;
  }

  isDone(ad) {
    return StoryFeedManager.isDone(ad);
  }

  reset() {
    super.reset();
    this._state.adCounter = 0;
  }
}
