import React, { useState, useCallback } from 'react';
import { LikeReaction } from '../../../../icons/ReactionLikes';
import styles from './reactionLikes.module.scss';

const mockData = {
  subTitle: 'DO YOU AGREE?',
  likes: 18,
  dislikes: 23,
};
export const ReactionLikes = ({ data = mockData }) => {
  const [activeReaction, setActiveReaction] = useState('');
  const handleReactionClick = useCallback((reaction) => {
    if (activeReaction === reaction) {
      setActiveReaction('')
      return;
    }
    setActiveReaction(reaction)
  }, [activeReaction]);

  return (
    <footer className={styles.actionWrapper}>
      <span className={styles.subTitle}>{data.subTitle}</span>
      <div className={styles.actions}>
        <button className={`${styles.actionBtn} ${activeReaction && activeReaction === 'like' ? styles.active : ''}`} onClick={() => handleReactionClick('like')}>
          <LikeReaction className={styles.icon}/>
        </button>
        <span className={styles.label}>{data.likes}</span>
        <button className={`${styles.actionBtn} ${styles.dislike} ${activeReaction && activeReaction === 'dislike' ? styles.active : ''}`} onClick={() => handleReactionClick('dislike')}>
          <LikeReaction className={styles.icon}/>
        </button>
        <span className={styles.label}>{data.dislikes}</span>
      </div>
    </footer>
  );
};

export default ReactionLikes;
