export const PenOutlined = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    className={className}
  >
    <path stroke="#3B2738" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11.018 4.223c.664 1.042 1.66 1.978 2.764 2.53m-2.764-2.53c-2.55 2.617-5.11 5.24-7.642 7.873l-.103.56c-.143.778-.282 1.557-.423 2.335a.14.14 0 0 0 .163.163 454.145 454.145 0 0 0 2.9-.531c2.638-2.64 5.251-5.252 7.87-7.87m-2.764-2.53c.292-.306.589-.608.884-.911a1.125 1.125 0 0 1 1.56-.053l1.126 1.013c.479.431.498 1.176.042 1.632l-.849.849"/>
  </svg>
);
