import PropTypes from 'prop-types';
import LinkWithTracking from '../LinkWithTracking';
import styles from './sponsor.module.scss';

export const Sponsor = ({
  data,
  className = '',
  isPromoted = false,
  showLogo= true,
  trackingData = {},
  label = 'Presented By'
}) => {
  const { client_data = {}, display_name } = data;
  const { images = {}, url = '' } = client_data;
  return (
    <header className={`${styles.brand} ${className} ${isPromoted ? styles.isPromoted : ''}`}>
      {!!images?.standard?.length && showLogo && (
        <div className={styles.logo}>
          <img src={images.standard} alt={display_name} loading="lazy"/>
        </div>
      )}
      <span className={styles.name}>
        <span className={styles.label}>{label} </span>
        <LinkWithTracking href={url} commonTrackingData={trackingData}>{display_name}</LinkWithTracking>
      </span>
    </header>
  );
};

Sponsor.propTypes = {
  data: PropTypes.shape({
    client_data: PropTypes.shape({
      images: PropTypes.shape({
        standard: PropTypes.string,
      }),
      url: PropTypes.string,
    }),
    display_name: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  isPromoted: PropTypes.bool,
  showLogo: PropTypes.bool,
  trackingData: PropTypes.object,
  label: PropTypes.string,
};

export default Sponsor;
