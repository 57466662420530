import styles from './FeedsButton.module.scss';

export const FeedsButton = ({ className = '', title, onClick, url = '' }) => {
  return (
    <>
      {!!url.length ? (
        <a href={url} className={`${styles.button} ${className}`} onClick={onClick}>
          {title}
        </a>
      ) : (
        <button className={`${styles.button} ${className}`} type="button" onClick={onClick}>
          {title}
        </button>
      )}
    </>
  );
};

export default FeedsButton;
