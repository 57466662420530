import { useRef } from 'react';
import PropTypes from 'prop-types';
import { StickyAd } from '../Ads/units/AdStickySidebar';
import styles from './FeedContent.module.scss';

export const AdStickySidebar = ({ position }) => {
  const localSidebarRef = useRef(null);

  let positionStr = 'promo-infinite';

  if (typeof position === 'string' && position) {
    positionStr = position;
  }

  return (
    <aside className={styles.sidebar} ref={localSidebarRef}>
      <StickyAd
        // Count up to `promo10` then use `promo-infinite` for remainder
        adPos={(position < 11) ? `promo${position}` : positionStr}
        sidebarEl={localSidebarRef}
        className={styles.sidebarAd}
        topPadding={28}
      />
    </aside>
  );
};

AdStickySidebar.propTypes = {
  position: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
};

export default AdStickySidebar;
