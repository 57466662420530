import styles from '../funmodule.module.scss';

export const TopComment = (props) => {
  const { linkUrl, image, linkTitle, userName, text } = props;
  const handleLinkClick = () => {
    console.log('Link clicked');
  };
  return (
    <section className={`${styles.container} ${styles.waves8} ${styles.twoColumns} ${styles.topComment}`}>
      <a
        className={styles.textWrapper}
        href={linkUrl}
        onClick={handleLinkClick}
      >
        <h2 className={styles.subTitle}>Top Comment</h2>
        <img src={image} alt={linkTitle} loading="lazy"/>
        <h3 className={styles.title}><span className={styles.wrap}>{linkTitle}</span></h3>
        <blockquote>
          <div className={styles.text}>
            <p>{text}</p>
          </div>
          <cite>&ndash; {userName}</cite>
        </blockquote>
      </a>
    </section>
  );
};

export default TopComment;
