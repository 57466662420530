import adsConfig from '../../../constants/ads-config';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { buildPosBound, getWireframeOptions, addBillboards } from '../../../utils/ads/ad-unit';
import AdUnit from '../../Ads/AdUnit';
import AdPlaceholder from './AdPlaceholder';

function buildPos(posnum) {
  return buildPosBound('story', posnum, 40);
}

export const AdSectionWide = ({
  className = '',
  config = null,
  isMobile = false,
  posnum = null,
  stickyWithinPlaceholder = false,
}) => {
  const screenSize = useMediaQuery();

  if(!screenSize) {
    return null;
  }

  const pos = buildPos(posnum);
  let cfg;
  if (!config) {
    cfg = { ...adsConfig[pos] };
  } else {
    cfg = { ...config };
  }

  let extraClasses = `Ad--section-wide xs-hide sm-block ${className}`.trim();

  function handleLayoutChange(bfpName) {
    if (bfpName === 'bfp_spotlight_ad') {
      return 'wide';
    }
    return null;
  }

  if(screenSize.isLargeScreen) {
    cfg.size = addBillboards(cfg);
  }

  return (!isMobile &&
    <AdUnit
      config={({...cfg, ...getWireframeOptions(cfg)})}
      className={extraClasses}
      getBFPLayout={handleLayoutChange}
      stickyWithinPlaceholder={stickyWithinPlaceholder}
    />
  );
};

export const AdSectionSmall = ({
  className = '',
  config = null,
  isMobile = true,
  posnum,
  stickyWithinPlaceholder = false,
}) => {
  const pos = config ? null : buildPos(posnum);
  const extraClasses = `md-hide lg-hide ${className}`.trim();
  return (isMobile &&
    <AdUnit
      className={extraClasses}
      config={config}
      stickyWithinPlaceholder={stickyWithinPlaceholder}
      type={pos}
    />
  );
};

export const AdSection = (props) => {
  return (
    <AdPlaceholder className="ad-placeholder-section">
      <AdSectionSmall {...props} />
      <AdSectionWide {...props} />
    </AdPlaceholder>
  );
};
