import PropTypes from 'prop-types';
import AddYoursDiscussion from './AddYoursDiscussion';
import ArticleCarousel from './ArticleCarousel';
import InOut from './InOut';
import Voting from './Voting';
import TopComment from './TopComment';
import styles from './funmodule.module.scss';

const FunModuleComponents = {
  voting: Voting,
  discussion_question: AddYoursDiscussion,
  top_comment: TopComment,
  article_carousel: ArticleCarousel,
  in_out: InOut,
};

const zonesWithColumnContainer = [
  'discussions',
];

const getContainerClassName = (name) => {
  if (zonesWithColumnContainer.includes(name)) {
    return `${styles.columnContainer} ${styles.notSingleColumn}`
  }

  return styles.scrollableContainer;
};

export const FunModule = ({
  className = '',
  items = [],
  zoneName = '',
  isTrackable = false,
  trackingData = {}
}) => {
  if (items?.length === 1) {
    const item = items[0];
    const { object_type: objectType } = item;
    const Component = FunModuleComponents[objectType];
    return (
      <section className={className}>
        <Component
          item={item}
          isTrackable={isTrackable}
          trackingData={{ position_in_unit: 0, ...trackingData }}
          index={0}
        />
      </section>
    );
  }

  const containerClassName = getContainerClassName(zoneName);

  /**
   * `items` in `data` represents all feed items within a zone. When it is defined, we will need to
   * render a parent container for the list of fun module feed items.
   */
  return (
    <section className={className}>
      <div className={containerClassName}>
        {items.map((item, index) => {
          const objectType = item.object_type;
          const Component = FunModuleComponents[objectType];
          return (
            <div className={styles.slide} key={index}>
              <Component
                item={item}
                isTrackable={isTrackable}
                trackingData={{ ...trackingData, position_in_unit: index}}
                index={index}
              />
            </div>
          )
        })}
      </div>
    </section>
  );
};

FunModule.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default FunModule;
