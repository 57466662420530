import React from 'react';
import { PenOutlined } from '../../icons/PenOutlined';
import LinkWithTracking from '../LinkWithTracking';
import styles from './feedBottom.module.scss';

export const FeedBottom = ({ hasSponsor = false, isTrackable = false, trackingData = {} }) => {
  return (
    <div className={`${styles.feedBottom} ${hasSponsor ? styles.sponsored : ''}`}>
      <h2>You’re up to date 🎉 Come back later for new content</h2>
      {!hasSponsor && isTrackable && (
        <LinkWithTracking
          href="/community/contribute"
          className={styles.button}
          commonTrackingData={{
            item_type: 'button',
            item_name: 'write_your_own_post',
            target_content_type: 'feed',
            target_content_id: 'community_contribute',
            ...trackingData
          }}
        >
          <PenOutlined className={styles.icon} /> Write your own post or quiz
        </LinkWithTracking>
      )}
      {!hasSponsor && !isTrackable && (
        <a href="/community/contribute" className={styles.button}>
          <PenOutlined className={styles.icon} /> Write your own post or quiz
        </a>
      )}
    </div>
  );
}

export default FeedBottom;
